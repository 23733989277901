body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
   height: 100%;
   width : 100%;
   margin: 0;
   padding: 0;
}

.chart-container {
	border-bottom: 1px solid gray;
}

#header {
	background: rgb(83 116 136);
	margin: 0;
	padding: .5em;
	border: none;
}

#header ul {
	padding: 0;
	margin: 0;
}

#header li {
	color: rgb(248, 241, 241);
	display: inline;
	margin: 0;
	padding: 0;
	margin-left: 1em;
	text-decoration: none;
}

#header li a {
	text-decoration: none;
	color: white;
}

#header li a:hover {
	text-decoration: underline;
	color: white;
}

#header li a:visited {
	color: white;
}

.content{
	position: relative;
	display: block;
	min-height: 800px;
	margin: 0;
    padding: .5em;
}

.footer {
	display: block;
	position: relative;
	width: 100%;
	background: gray;
	color: white;
	text-align: center;
	padding: 1em 0;
}

.errors {
	color: rgb(199, 1, 1);
	font-size: 0.6em;
}

.register-cont {
	padding: 2em;
}

.register-cont input{
	width: 300px;
	height: 1.5em;
	padding: .5em;
	font-size: 1em;
}

label {
	color: gray;
}

input[type=submit] {
	background: rgb(83 116 136);
	color: white;
	border: none;
	border-radius: 5px;
	padding: .2em;
}

.header-float-right{
	float: right;
}

.logo-image{
	width: 32px;
}

.project-container {
	position: relative;
	margin-bottom: 10px;
	background: #eee;
	border: 1px solid rgb(83 116 136);
	border-radius: 5px;
	padding-left: 1em;
}

.project-settings, .project-posts {
	display: inline-block;
	position: relative;
	vertical-align: top;
	width: 49%;
}


.project-container button{
	margin-bottom: 5px;
	cursor: pointer;
}

.project-delete {
	position: absolute;
	top: 2px;
	right: 2px;
}

.keyword {
	display: inline-block;
	position: relative;
	width: auto;
	margin-bottom: 10px;
	background: rgb(83 116 136);
	border: none;
    color: white;
	border-radius: 5px;
	padding: 5px 10px;
	margin: .5em;
	font-size: .7em;
    cursor: pointer;
}

.keyword-gray {
	display: inline-block;
    position: relative;
    width: auto;
    /* margin-bottom: 10px; */
    background: rgb(241, 241, 241);
    border: 1px solid rgb(109, 109, 109);
    color: black;
    border-radius: .5em;
    padding: .3em .5em;
    min-width: 8em;
    margin: .1em;
    font-size: .8em;
    cursor: pointer;
}

.keyword-no-width{
    min-width: auto;
    float: right;
}

.data-set hr {
    border: none;
    border-top: 1px dashed #ccc;
}

hr {
    border: none;
    border-top: 1px solid #ccc;
}

.data-set input {
    border-radius: .3em;
    border: 1px solid #ccc;
    margin-bottom: .3em;
}

.react-select-container {
    width: 60%;
    margin: 1em;
    z-index: 2;
}

.data-set .react-select-container {
    margin-top: .5em;
}




.product {
	display: inline-block;
	position: relative;
	width: auto;
	margin-bottom: 10px;
	background: none;
	border: 1px solid rgb(83 116 136);
    color: rgb(83 116 136);
	border-radius: 5px;
	padding: 5px 10px;
	margin: .5em;
	font-size: .5em;
    cursor: pointer;
}

.keyword-delete-button {
	margin-left: 5px;
    border-radius: 30px;
    cursor: pointer;
}

.post {
	font-size: .8em;
	padding: 1em;
	border-bottom: 1px solid #eee;
	color: gray;
}

.picker-input__text {
	margin-left: 1em;
	margin-right: 1em;
	padding: 2px !important;
}

select {
	margin-left: 1em;
    margin-right: 1em;
}

.add-chart-button-cont {
	width: 100%;
	text-align: center;
	padding-top: 1em;
	padding-bottom: 1em;
}

.submenu {
    background: rgb(32, 32, 32);
    color: white;
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 14%;
    min-height: 1600px;
    text-align: left;
    padding: 1%;
}

.submenu h2{
    margin-bottom: 0;
}

.submenu ul{
    list-style: none;
    font-size: 1em;
    padding: 0;
    margin-top: 0;
    margin-bottom: 2em;
    
}
.submenu ul li{
    color: rgb(148, 148, 148);
    cursor: pointer;
    border-bottom: 1px solid rgb(46, 46, 46);
    padding: .5em 0;
    padding-left: .5em;
}
.submenu ul li:hover{
    color: rgb(240, 240, 240);
}

.right-content {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 82%;
    padding: 1%;
}
.right-content div{
    /* width: 96%; */
    /* margin-left: 2%; */
    overflow-wrap: break-word;
}

.chart-controls {
    position: relative;
    width: 100%;
}


.datasets-container {
    width: 25%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding-top: 2.5em;
}

.chart-container-in {
    width: 75%;
    position: relative;
    display: inline-block;
}

.data-set {
    position: relative;
    display: block;
    border-radius: .5em 0 .5em 0;
    border: 1px solid #d4d4d4;
    margin: .5em;
    padding: .5em;
    font-size: .7em;
}

.dataset-hidden {
    display: none;
}

.highcharts-credits {
    display: none;
}

.date-selector {
    display: inline-block;
    position: relative;
    border: 1px solid rgb(211, 211, 211);
    padding: .2em;
    margin: 0 .5em;
}

.popup-content {
    height: 90% !important;
    overflow-y: scroll !important;
    width: 80% !important;
}

.dataset-selected-product {
    background: rgb(83 116 136);
    color: white;
}


.preview-table {
    border-collapse: collapse;
    font-size: .8em;
    width: 100%;
}

/* .preview-table tr:first-child td {
    color: white;
    background: rgb(124, 124, 124);
} */

.preview-table th {
    position: sticky;
    top: 0;
   color: white;
    background: rgb(124, 124, 124); 
    padding: .5em;
    z-index: 1;
}

.preview-table td{
    border: 1px solid #ddd;
    padding: 5px;
    position: relative;
    padding: .5em;
    text-align: right;
}

.table-cont {
    width: 100%;
    overflow-x: scroll;
    
}

.table-short {
    height: 400px;
    overflow-y: scroll;
}

/* .react-select-container {
    width: 20em;
} */

.import-info {
    display: inline-block;
    color: #555;
    font-style: italic;
    width: 50%;
}

.selector{
    position: relative;
    display: block;
    width: 90%;
    padding: .4em;
    border: 1px solid gray;
    border-radius: .3em;
    margin-bottom: 1em;
}

.selector input{
    position: relative;
    display: block;
    width: 94%;
    padding: .4em
}

.selector .selector-filter {
    color: black;
    font-size: .8em;
}

.selector .selector-filter input{
    display: inline-block;
    width: .5em;
    color: black;
}


.selector-options {
    font-size: .8em;
    position: absolute;
    max-height: 18em;
    width: 96%;
    z-index: 2;
    background-color: white;
    border: gray;
    box-shadow: 5px 5px .5;
    overflow-y: scroll;
    border: 1px solid #ddd;
    border-top: none;
    box-sizing: border-box;
    -webkit-box-shadow: 2px 11px 10px -2px rgba(0,0,0,0.2);
    -moz-box-shadow: 2px 11px 10px -2px rgba(0,0,0,0.2);
    box-shadow: 2px 11px 10px -2px rgba(0,0,0,0.2);
    border-bottom: 3px solid white;
}

.selector-options span{
    display: block;
    color: #666;
    cursor: pointer;
    padding-left: 1em;
    padding-top: .4em;
}
.selector-options span:hover{
    display: block;
    color: white;
    background: gray;
    cursor: pointer;
}

.selector-clear {
    position: absolute;
    top: .5em;
    right: .9em;
    cursor: pointer;
}

.selector-selected div {
    font-size: .6em;
    background: #666;
    padding: .3em .5em;
}
.selector-selected div button {
    margin-left: .5em;
    padding: .2em;
    padding-top: 0;
}

.sales-filters {
    display: inline-block;
    width: 20%;
    vertical-align: top;
}

.sales-results {
    display: inline-block;
    width: 80%;
    vertical-align: top;
}




.sales-status {
    text-align: center;
    padding-top: 5em;
    color: gray;
}

.edit-product {
    position: relative;
    display: inline-block;
    margin: 1em 2em;
}

.edit-product span {
    position: relative;
    width: 10em;
    display: inline-block;
}

.edit-product input{
    padding: .3em;
    vertical-align: bottom;
}

.edit-product .update {
    float: right;
}


.product-row-brand {
    font-size: .8em;
    color: #444;
    
}
.product-row-brand span{
    float: right;
    margin-left: 1em;
    color: #999;
}

.table-color-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* top: 5%;
    left: 5%; */
    z-index: -1;
}

.info-section {
    border-bottom: 1px solid gray;
}

.info-section-data {
    margin-left: 1em;
    
}
.info-section-row {
    border-bottom: 1px solid gray;
}

.info-section-row h2 {
    margin-bottom: 0;
}
.info-section-data h3 {
    margin-bottom: 0;
    margin-top: 0;
    font-weight: normal;
}
.info-section-data p {
    margin-top: 0;
    color: #666;
    font-style: italic;
    font-size: .8em;
}

.info-section-values {
    border: 1px solid #ccc;
    border-radius: .5em;
    display: inline-block;
    width: 90%;
    font-size: .8em;
    padding: .5em;

}


.info-section-value {
    margin: .1em .2em;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: .5em;
    padding: .1em .3em;
}
.info-section-value:after {
    /* content: ','; */
}

.info-section-value:last-child:after {
    /* content: ''; */
}

.compare {
    font-size: .7em;
    opacity: .5;
}


.logs-right {
    display: inline-block;
    width: 83%;
    max-height: 500px;
    vertical-align: top;
    overflow-x: scroll;
    text-align: center;
}

.logs-left .import-info {
    width: 100%;
}

.logs-left {
    display: inline-block;
    width: 16%;
    font-size: .8em;
}

.logs-left ul {
    margin-left: 0;
    padding: 0;
    margin-top: 0;
}

.logs-left li {
    /* font-size: .7em; */
    color: rgba(211,0,0,1);
    list-style-type: none;
    margin-left: 0;
    margin-bottom: .3em;
    cursor: pointer;

}

.logs-left li:hover {
    color: rgba(211,0,0,.6);
}

.logs-delete-button {
    display: block;
}


/* div:empty:before {
   content: 'This div is empty';
} */

/* MAP */

#open-map {
    display: inline-block;
    width: 92%;
    vertical-align: top;
}

.map-days-buttons {
    display: inline-block;
    width: 7%;
    vertical-align: top;
}

.map-days-buttons button{
    display: inline-block;
    margin: 0;
    padding-left: .2em;
    padding-right: .2em;
    margin-top: .2em;
    width: 4em;
}

.leaflet-map{
    height: 80vh;
    width: 80%;
    margin-left: 10%;
}

/* S1 */
.leaflet-marker-S1A1{
    background-color: #ff3300;
    border-radius: 50%;
}
.leaflet-marker-S1A2{
    background-color: #0000ff;
    border-radius: 50%;
}
.leaflet-marker-S1A3{
    background-color: #00cc00;
    border-radius: 50%;
}
.leaflet-marker-S1A4{
    background-color: #ffff00;
    border-radius: 50%;
}
.leaflet-marker-S1A5{
    background-color: #cc66ff;
    border-radius: 50%;
}
.leaflet-marker-S1A6{
    background-color:#006666;
    border-radius: 50%;
}
.leaflet-marker-S1C1{
    background-color: #bf4040;
    border-radius: 50%;
}
/* S2 */
.leaflet-marker-S2A1{
    background-color: #3333cc;
    border-radius: 50%;
}
.leaflet-marker-S2A2{
    background-color: #32e71a;
    border-radius: 50%;
}
.leaflet-marker-S2A3{
    background-color: #ffcc66;
    border-radius: 50%;
}
.leaflet-marker-S2A4{
    background-color: #00ff99;
    border-radius: 50%;
}
.leaflet-marker-S2A5{
    background-color: #669900;
    border-radius: 50%;
}
.leaflet-marker-S2A6{
    background-color:#14A989;
    border-radius: 50%;
}
.leaflet-marker-S2C1{
    background-color: #84DE02;
    border-radius: 50%;
}
/* S3 */
.leaflet-marker-S3A1{
    background-color: #330080;
    border-radius: 50%;
}
.leaflet-marker-S3A2{
    background-color: #ff751a;
    border-radius: 50%;
}
.leaflet-marker-S3A3{
    background-color: #2d5986;
    border-radius: 50%;
}
.leaflet-marker-S3A4{
    background-color: #00b33c;
    border-radius: 50%;
}
.leaflet-marker-S3A5{
    background-color: #990099;
    border-radius: 50%;
}
.leaflet-marker-S3A6{
    background-color:#85adad;
    border-radius: 50%;
}
.leaflet-marker-S3C1{
    background-color: #663300;
    border-radius: 50%;
}
/* S4 */
.leaflet-marker-S4A1{
    background-color: #01786F;
    border-radius: 50%;
}
.leaflet-marker-S4A2{
    background-color: #ED0A3F;
    border-radius: 50%;
}
.leaflet-marker-S4A3{
    background-color: #391285;
    border-radius: 50%;
}
.leaflet-marker-S4A4{
    background-color: #BD559C;
    border-radius: 50%;
}
.leaflet-marker-S4A5{
    background-color: #FFFF38;
    border-radius: 50%;
}
.leaflet-marker-S4A6{
    background-color:#6B3FA0;
    border-radius: 50%;
}
.leaflet-marker-S4A7{
    background-color:#8b2230;
    border-radius: 50%;
}
.leaflet-marker-S4C1{
    background-color: #ACACE6;
    border-radius: 50%;
}
/* S5 */
.leaflet-marker-S5A1{
    background-color: #ccccff;
    border-radius: 50%;
}
.leaflet-marker-S5A2{
    background-color: #ff66ff;
    border-radius: 50%;
}
.leaflet-marker-S5A3{
    background-color: #cc0044;
    border-radius: 50%;
}
.leaflet-marker-S5A4{
    background-color: #00e6e6;
    border-radius: 50%;
}
.leaflet-marker-S5A5{
    background-color: #ff8566;
    border-radius: 50%;
}
.leaflet-marker-S5A6{
    background-color:#79ff4d;
    border-radius: 50%;
}
.leaflet-marker-S5C1{
    background-color: #269900;
    border-radius: 50%;
}

.sr-only {
    display: none;
  }

.brands select{
    margin: 0;
}

.brands input {
    padding: .5em;
    border: 1px solid  rgb(165, 165, 165);
    border-radius: .2em;
    
}

.brands .input {
    margin-bottom: 2em;
}

.brands .inputs {
    display: inline-block;
    position: relative;
    width: 20%;
    vertical-align: top;
}

.brands .list {
    display: inline-block;
    position: relative;
    width: 25%;
    vertical-align: top;
}

.brands .list ul {
    list-style-type: none;
    padding-left: 0;
    position: relative;
}

.brands .list ul li{
    width: 90%;
    border-bottom: 1px solid rgb(185, 185, 185);
    position: relative;
    height: 1.4em;
    margin-bottom: .5em;
}

.brands .list ul li sup {
    font-size: .8em;
}
.brands .list ul li sup:before {
    content: '(id:';
    color: gray;
}
.brands .list ul li sup:after {
    content: ')';
    color: gray;
}

.brands-list:before {
    content: '(producer:'!important;
}

.sub-brands-list:before {
    content: '(brand:'!important;
}

.brands .list ul li button{
    position: absolute;
    right: 0;
    top: -.5em;
    font-size: .5em;
    padding: .5em .8em;
    cursor: pointer;
}

.brands .list ul li button:hover{
    opacity: .7;
}

.brands-btn-edit{
    right: 2.5em!important;
}

.brands label {
    font-size: .8em;
    display: block;
}


.survey-marker {
    background: white;
    border-radius: 5px;
    padding: 5px;
    font-size: 10px;
    width: auto !important;
}

.survey-maps {
    position: relative;
    width: 100%;
}

.survey-maps .description {
    font-style: italic;
    font-weight: lighter;
    border-top: 1px solid rgb(204, 204, 204);
    padding-top: 1em;
    padding-left: 1em;
}

.survey-maps .description:before {
    content: '*';
}

#survey-open-map {
    display: inline-block;
    width: 50%;
    vertical-align: top;
}

.survey-maps .marker-data {
    font-weight: lighter;
    font-style: italic;
    padding-top: 1em;
    padding-left: 1em;
    width: 40%;
    margin-left: 30%;
    text-align: left;
}

.survey-maps .marker-data p {
    vertical-align: top;
}

#survey-simple-map {
    display: inline-block;
    width: 49%;
    vertical-align: top;
}

#survey-simple-map svg {
    margin-top: 1em;
}

.leaflet-marker {
    background-color: #CC0000;
    border-radius: 50%;
}

.leaflet-tile-pane {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}


.survey-table table{
    border-collapse: collapse;
    
}
.survey-table td{
    border: 1px solid black;
    padding: .2em 2em;
    text-align: center;
    position: relative;
}   
.survey-td-bck {
    position: absolute;
    top: 0;
    left: 0;
    background: #cc0000;
    width: 100%;
    height: 100%;
    opacity: .3;
    z-index: -3;
}

/* Switch button */
.switch-box .switch-option {
    margin: 0 7px;
}
.survey-switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 23px;
    border: 1px solid red;
  }
  
  .survey-switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .survey-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .survey-slider:before {
    position: absolute;
    content: "";
    height: 27px;
    width: 27px;
    left: 0px;
    bottom: -2px;
    background-color: red;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 5px;
  }
  
  input:checked + .survey-slider {
    background-color: white;
  }
  
  input:focus + .survey-slider {
    box-shadow: 0 0 1px white;
  }
  
  input:checked + .survey-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }